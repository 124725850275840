import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <footer>
    {/*
      <div className="container">
        <div className="footer-widget-row">
          <div className="footer-widget">
            <div className="widget-title">
              <img src="/images/ft-logo.png" alt="" />
            </div>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eaccusam et justo duo dolores
              eteem.
            </p>
          </div>
          <div className="footer-widget contact-widget">
            <div className="address">
              <h4 className="text-uppercase">contact Info</h4>
              <p> 239/2 NK Street, DC, USA</p>
              <p> Phone: +123 456 78900</p>
              <a href="mailto:theme@kotha.com">theme@kotha.com</a>
            </div>
          </div>
          <div className="footer-widget social-widget" />
        </div>
      </div>
    */}
    <div className="container-fluid text-center ft-copyright">
      <p>&copy; 2019 - USA2Z</p>
    </div>
  </footer>
)

export default Footer
