import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

class Navi extends React.Component {
  render() {
    const { location, title } = this.props
    return (
      <StaticQuery
        query={graphql`
          query NaviQuery {
            site {
              siteMetadata {
                author
                about
                facebook_url
                twitter_url
                instagram_url
              }
            }
          }
        `}
        render={data => (
          <header className="kotha-menu marketing-menu">
            <nav className="navbar  navbar-default">
              <div className="container">
                <div className="menu-content">
                  {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#myNavbar"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div className="collapse navbar-collapse" id="myNavbar">
                    <ul className="nav navbar-nav text-uppercase pull-left">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about/">About</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- /.container-fluid --> */}
            </nav>
            {/*
              <div className="kotha-logo text-center">
                <h1>
                  <Link to="/">
                    <img src="/images/travel-logo.png" alt="KothaPro" />
                  </Link>
                </h1>
              </div>
            */}
          </header>
        )}
      />
    )
  }
}

export default Navi
